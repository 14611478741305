<template>
  <div>
    <b-tabs>
      <b-tab v-if="acesso.RelatorioSimuladorProposta">
        <template #title>
          <feather-icon icon="ShoppingBagIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Proposta</span>
        </template>
        <relatorioProposta />
      </b-tab>

      <b-tab v-if="acesso.RelatorioSimuladorExterno">
        <template #title>
          <feather-icon icon="ShoppingBagIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Simulador Externo</span>
        </template>
        <RelatorioSimuladorExterno />
      </b-tab>
    </b-tabs>
    <b-card class="text-center" v-if="todosFalsos">
      <b-card-body>
        <b-icon icon="exclamation-triangle" variant="danger" font-scale="2"></b-icon>
        <b-card-title class="text-danger mt-2">Acesso Negado!</b-card-title>
        <b-card-text class="mb-1">Parece que seu usuário não tem permissão para acessar abas deste módulo.</b-card-text>
        <b-card-text class="mb-1"
          >Para obter acesso, por favor, solicite assistência ou permissões adicionais ao administrador do sistema.</b-card-text
        >
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import relatorioProposta from './relatorioProposta.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import RelatorioSimuladorExterno from './relatorioSimuladorExterno.vue'

  export default {
    components: {
      relatorioProposta,
      ToastificationContent,
      RelatorioSimuladorExterno,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        acesso: {
          RelatorioSimuladorProposta: false,
          RelatorioSimuladorExterno: false,
        },
      }
    },
    computed: {
      todosFalsos() {
        return Object.values(this.acesso).every((valor) => valor === false)
      },
    },

    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.RelatorioSimuladorProposta = true
        this.acesso.RelatorioSimuladorExterno = true
      } else {
        this.acesso.RelatorioSimuladorProposta = this.userData.GrupoAcesso.RelatorioSimuladorProposta
        this.acesso.RelatorioSimuladorExterno = this.userData.GrupoAcesso.RelatorioSimuladorExterno
      }
    },
  }
</script>
