import * as XLSX from 'xlsx'

const createSheet = (tableData, fileName) => {
  // cria um objeto de planilha vazio
  const workbook = XLSX.utils.book_new()
  // cria uma planilha vazia e adiciona ao objeto de planilha
  const worksheet = XLSX.utils.aoa_to_sheet([[]])
  XLSX.utils.book_append_sheet(workbook, worksheet)

  // adiciona dados da tabela à planilha
  XLSX.utils.sheet_add_json(worksheet, tableData, { origin: -1 })

  // salvar o arquivo XLSX
  XLSX.writeFile(workbook, `${fileName}.xlsx`)
}

export const asDefault = ({ fileName = 'Download' }) => {
  return {
    convertToExcelFormat: (item) => {
      return item
    },
    export: (items) => {
      const situationItems = items
      createSheet(situationItems, fileName)
    },
  }
}
