<template>
  <div>
    <RelatorioRetrato>
      <span v-if="parametro.periodo.dataInicio != ''">Período: De {{ formatarData(parametro.periodo.dataInicio) }} </span>
      <span v-else-if="parametro.periodo.dataFim != ''"> Desde o início do sistema </span>
      <span v-if="parametro.periodo.dataFim != ''"> até {{ formatarData(parametro.periodo.dataFim) }} </span>
      <span v-else-if="parametro.periodo.dataInicio != ''"> até hoje </span>

      <b-row>
        <b-table
          striped
          small
          class="ExportarPDFParcela mt-3 custom-header-style"
          style="text-align: center"
          id="ExportarPDFParcelaId"
          :head-variant="'light'"
          :items="parametro.dados"
          :fields="parametro.colunas"
        >
        </b-table>
      </b-row>
    </RelatorioRetrato>
  </div>
</template>

<script>
  import pdfMake from 'pdfmake/build/pdfmake'
  import pdfFonts from 'pdfmake/build/vfs_fonts'
  import RelatorioRetrato from '@/views/pages/relatorio/relatorioRetrato.vue'
  import { formatarData, formatarParaNumber, formatarValor, formatarValorPorcentagem } from '@/libs/utils'

  export default {
    components: {
      RelatorioRetrato,
      pdfMake,
      pdfFonts,
      formatarValor,
      formatarData,
    },
    props: {
      parametro: {
        type: Object,
        default: () => ({
          dados: [],
          colunas: [],
          periodo: {
            dataInicio: '',
            dataFim: '',
          },
        }),
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        formatarValor,
        formatarData,
      }
    },
  }
</script>
