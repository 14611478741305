<template>
  <div>
    <!-- FILTROS -->
    <b-card>
      <h2>Filtros</h2>
      <validation-observer ref="formPesquisar">
        <b-form class="mt-2">
          <!-- Filtros Simples -->
          <b-row>
            <b-col md="2">
              <b-form-group label="Pesquisar Por:" label-for="pesquisarPor">
                <v-select-pt
                  id="pesquisarPor"
                  :clearable="false"
                  v-model="pesquisarPor"
                  :options="tipoPesquisa"
                  label="name"
                  class="tw-w-full tw-max-w-md tw-mb-4 tw-rounded-md tw-shadow-sm tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-sm:text-sm"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Descrição" label-for="pesquisar">
                <b-form-input
                  v-if="pesquisarPor.id == 1"
                  id="pesquisar"
                  type="text"
                  v-model="descricao"
                  placeholder="Nome"
                  class="form-control cleave-height"
                  :raw="false"
                />
                <cleave
                  v-else-if="pesquisarPor.id == 2"
                  id="pesquisar"
                  type="text"
                  v-model="descricao"
                  placeholder="Nº Contrato"
                  class="form-control cleave-height"
                  :raw="false"
                  :options="options.cleaveCodigo"
                />
                <cleave
                  v-else-if="pesquisarPor.id == 3"
                  id="pesquisar"
                  type="text"
                  v-model="descricao"
                  placeholder="CPF"
                  class="form-control cleave-height"
                  :raw="false"
                  :options="options.cleaveCPF"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Status" label-for="statusProposta">
                <v-select-pt
                  id="statusProposta"
                  :clearable="false"
                  v-model="statusProposta"
                  :options="statusOpcoes"
                  label="name"
                  class="tw-w-full tw-max-w-md tw-mb-4 tw-rounded-md tw-shadow-sm tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-sm:text-sm"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Data Inicial" label-for="dataInicial">
                <validation-provider #default="{ errors }" name="Data Inicial">
                  <b-input-group>
                    <cleave
                      id="dataInicial"
                      type="text"
                      v-model="dataInicial"
                      placeholder="DD/MM/AAAA"
                      class="form-control cleave-height"
                      :options="options.cleaveDate"
                      :raw="false"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        size="sm"
                        button-variant="primary"
                        class="datePickerWithInput"
                        right
                        button-only
                        v-model="dataInicialDatePicker"
                        :hide-header="true"
                        :initial-date="new Date()"
                        :state="errors[0] ? false : null"
                        label-help=""
                        label-calendar="Calendário"
                        label-close-button="Fechar"
                        label-current-month="Mês atual"
                        label-next-decade="Próxima década"
                        label-next-month="Próximo mês"
                        label-next-year="Próximo ano"
                        label-prev-decade="Década anterior"
                        label-prev-month="Mês anterior"
                        label-prev-year="Ano anterior"
                        label-select-month="Selecione o mês"
                        label-select-year="Selecione o ano"
                        label-selected="Selecionado"
                        label-no-date-selected="Nenhuma data selecionada"
                        label-reset-button="Resetar"
                        label-today-button="Hoje"
                        label-today="Hoje"
                        locale="pt-br"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                        aria-controls="dataInicial"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Data Final" label-for="dataFinal">
                <validation-provider
                  #default="{ errors }"
                  name="Data Inicial"
                  :rules="{
                    dataPassada: {
                      value: dataFinal,
                      dataParametro: dataInicial,
                      stringParametro: 'data inicial',
                    },
                  }"
                >
                  <b-input-group>
                    <cleave
                      id="dataFinal"
                      type="text"
                      v-model="dataFinal"
                      placeholder="DD/MM/AAAA"
                      class="form-control cleave-height"
                      :options="options.cleaveDate"
                      :raw="false"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        size="sm"
                        button-variant="primary"
                        class="datePickerWithInput"
                        right
                        button-only
                        v-model="dataFinalDatePicker"
                        :hide-header="true"
                        :initial-date="new Date()"
                        :state="errors[0] ? false : null"
                        label-help=""
                        label-calendar="Calendário"
                        label-close-button="Fechar"
                        label-current-month="Mês atual"
                        label-next-decade="Próxima década"
                        label-next-month="Próximo mês"
                        label-next-year="Próximo ano"
                        label-prev-decade="Década anterior"
                        label-prev-month="Mês anterior"
                        label-prev-year="Ano anterior"
                        label-select-month="Selecione o mês"
                        label-select-year="Selecione o ano"
                        label-selected="Selecionado"
                        label-no-date-selected="Nenhuma data selecionada"
                        label-reset-button="Resetar"
                        label-today-button="Hoje"
                        label-today="Hoje"
                        locale="pt-br"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                        aria-controls="dataFinal"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Vendedor" label-for="vendedor">
                <v-select-pt id="vendedor" v-model="vendedor" :options="vendedores" label="name" />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Botões -->
          <b-row align-h="end">
            <b-col md="auto">
              <b-button
                :disabled="isBusy"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                style="margin-top: 21px; width: 160px"
                @click="carregarGrid()"
              >
                {{ !isBusy ? 'Prévia' : '' }}
                <b-spinner small v-if="isBusy" />
              </b-button>
            </b-col>
            <b-col md="auto">
              <template>
                <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                  <template #button-content>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      style="margin-top: 21px; width: 160px"
                    >
                      Exportar
                    </b-button>
                  </template>
                  <b-dropdown-item href="#" @click="exportToPDF()">
                    <feather-icon icon="FileTextIcon" size="16" />
                    <span class="align-middle ml-50" title="Exportar para PDF">PDF</span>
                  </b-dropdown-item>
                  <b-dropdown-item href="#" @click="exportToExcel()">
                    <feather-icon icon="CheckCircleIcon" size="16" />
                    <span class="align-middle ml-50" title="Exportar para excel">Excel</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <!-- TABELA -->
    <b-card v-if="this.itemsTabela.length > 0 || isBusy">
      <template v-if="isBusy">
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando Tabela...</strong>
        </div>
      </template>

      <!-- TOTALIZADORES -->
      <div v-if="!isBusy" v-show="this.itemsTabela.length > 0">
        <b-row>
          <b-col md="12" id="containerTotalizadores" class="mb-2">
            <b-row>
              <b-col md="auto" style="margin: 0.3vw">
                Total de Propostas: <b-badge variant="primary"> {{ totalRows }}</b-badge>
              </b-col>
              <b-col md="auto" style="margin: 0.3vw" v-if="apresentaAprovado">
                Propostas Aprovadas: <b-badge variant="success"> {{ totalizadores.aprovado }}</b-badge>
              </b-col>
              <b-col md="auto" style="margin: 0.3vw" v-if="apresentaPendente">
                Propostas Pendentes: <b-badge variant="warning"> {{ totalizadores.pendente }}</b-badge>
              </b-col>

              <b-col md="auto" style="margin: 0.3vw" v-if="apresentaContratoGerado">
                Propostas com Contrato Consolidado: <b-badge variant="success"> {{ totalizadores.contratoGerado }}</b-badge>
              </b-col>

              <b-col md="auto" style="margin: 0.3vw" v-if="apresentaNegado">
                Propostas Negadas: <b-badge variant="danger"> {{ totalizadores.negado }}</b-badge>
              </b-col>

              <b-col md="auto" style="margin: 0.3vw" v-if="apresentaContratoCancelado">
                Propostas Cancelado : <b-badge variant="danger"> {{ totalizadores.contratoCancelado }}</b-badge>
              </b-col>

              <b-col md="auto" style="margin: 0.3vw" v-if="apresentaExpirado">
                Propostas Expiradas: <b-badge variant="secondary"> {{ totalizadores.expirado }}</b-badge>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <div ref="tabelaGrid" :itemsTabela="this.itemsTabela">
              <b-table
                style="overflow-x: auto; -webkit-overflow-scrolling: touch"
                outlined
                foot-clone
                id="tabelaGrid"
                ref="tabelaGrid"
                striped
                :per-page="perPage"
                :current-page="currentPage"
                :fields="currentFields"
                :items="itemsTabela"
                responsive="sm"
                class="position-relative custom-rounded-table auto-width-headers"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
              >
                <template v-slot:cell(statusProposta)="row">
                  <b-badge v-if="row.item.statusProposta == 'ContratoGerado'" variant="primary"> Contrato Gerado </b-badge>

                  <b-badge v-else-if="row.item.statusProposta == 'Aprovado'" variant="success">
                    {{ row.item.statusProposta }}
                  </b-badge>

                  <b-badge v-else-if="row.item.statusProposta == 'Pendente'" variant="warning">
                    {{ row.item.statusProposta }}
                  </b-badge>

                  <b-badge v-else-if="row.item.statusProposta == 'Negado'" variant="danger">
                    {{ row.item.statusProposta }}
                  </b-badge>

                  <b-badge v-else-if="row.item.statusProposta == 'ContratoCancelado'" variant="secondary">
                    Contrato Cancelado
                  </b-badge>

                  <b-badge v-else-if="row.item.statusProposta == 'Expirado'" variant="secondary">
                    {{ row.item.statusProposta }}
                  </b-badge>
                </template>

                <!-- Default fall-back custom formatted footer cell -->
                <template #foot()="">
                  {{ '' }}
                </template>
                <template #foot(dataCadastro)="">
                  {{ 'Total: ' }}
                </template>
                <template #foot(valorProposta)="">
                  {{ formatarValor(totalValorProposta) }}
                </template>
                <template #foot(valorParcela)="">
                  {{ formatarValor(totalValorParcela) }}
                </template>
              </b-table>

              <b-card-body v-if="this.itemsTabela.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
                <!-- page length -->
                <b-form-group
                  style="align-items: center"
                  label="Por Página"
                  label-cols="6"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1"
                >
                  <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
                </b-form-group>

                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="this.itemsTabela.length"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-card-body>
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <relatorioPropostasRetrato ref="exportarPDF" :parametro="parametroPDF" />
      </div>
    </b-card>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, dataPassada, valor } from '@validations'
  import { formatarData, formatarParaNumber, formatarValor, formatarValorPorcentagem, formatarValorReais } from '@/libs/utils'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import relatorioPropostasRetrato from './components/relatorioPropostasRetrato.vue'
  import { VueSelect } from 'vue-select'
  import { generateAndDownloadPdf } from '@/utils/geradorPDF/geradorPDF.js'
  import VueHtml2pdf from 'vue-html2pdf'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  import * as XLSX from 'xlsx'
  import { formatarCPF } from '@/utils/Functions/Formatacao/Formatacao'

  export default {
    components: {
      valor,
      VueHtml2pdf,
      ToastificationContent,
      formatarData,
      formatarValor,
      formatarValorReais,
      generateAndDownloadPdf,
      formatarValorReais,
      relatorioPropostasRetrato,
      useJwt,
      dataPassada,
      formatarData,
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      VueSelect,
      required,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        itemsTabela: [],
        formatarValor,
        valor: 0,
        isBusy: false,
        totalRows: 1,
        nomeRelatorio: 'Relatório de Propostas',
        descricao: '',
        statusProposta: { id: `Todos`, name: 'Todos' },
        valorProposta: '',
        valorParcela: '',
        dataCadastro: '',
        taxa: '',
        parcelas: '',
        dataGerado: '',
        proposta: '',
        pesquisarPor: { id: 1, name: 'Nome' },
        parametroPDF: {
          dados: [],
          colunas: [],
          totalizadores: { aprovado: 0, pendente: 0, negado: 0, contratoGerado: 0, contratoCancelado: 0, expirado: 0 },
          periodo: { dataInicio: '', dataFim: '' },
        },
        linhasTabela: [],
        linhasExcel: [],
        datePicker: {
          dataInicial: '',
          dataFinal: '',
        },
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        tipoPesquisa: [
          { id: 1, name: 'Nome' },
          { id: 2, name: 'Código' },
          { id: 3, name: 'CPF' },
        ],
        dataInicialDatePicker: '',
        dataFinalDatePicker: '',
        dataInicial: '',
        dataFinal: '',
        options: {
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
          cleaveCPF: {
            numericOnly: true,
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
          cleaveCodigo: {
            numericOnly: true,
            delimiters: ['/'],
            blocks: [6, 2],
          },
        },
        number: {},
        statusOpcoes: [
          { id: 'Todos', name: 'Todos' },
          { id: 'Pendente/Aprovado', name: 'Pendente/Aprovado' },
          { id: 'Aprovado', name: 'Aprovado' },
          { id: 'Pendente', name: 'Pendente' },
          { id: 'Negado', name: 'Negado' },
          { id: 'ContratoGerado', name: 'Contrato Gerado' },
          { id: 'ContratoCancelado', name: 'Contrato Cancelado' },
          { id: 'Expirado', name: 'Expirado' },
        ],
        colunasTabelas: [],
        currentFields: [
          {
            id: 'dataCadastro',
            key: 'dataCadastro',
            label: 'Data de Cadastro',
            sortable: true,
          },
          {
            id: 'proposta',
            key: 'proposta',
            label: 'Código',
            sortable: true,
          },
          {
            key: 'segurado.tomador.nomeCompleto',
            label: 'Nome',
            sortable: true,
          },
          {
            key: 'segurado.tomador.cpf',
            label: 'CPF',
            sortable: true,
          },
          {
            id: 'valorProposta',
            key: 'valorProposta',
            label: 'Valor Proposta',
            sortable: true,
            formatter: (value) => formatarValor(value),
          },
          {
            id: 'valorParcela',
            key: 'valorParcela',
            label: 'Valor Parcela',
            sortable: true,
            formatter: (value) => formatarValor(value),
          },
          {
            id: 'taxa',
            key: 'taxa',
            label: 'Taxa',
            sortable: true,
          },
          {
            id: 'parcelas',
            key: 'parcelas',
            label: 'Nº Parcelas',
            sortable: true,
          },
          {
            id: 'statusProposta',
            key: 'statusProposta',
            label: 'Status',
            sortable: true,
          },
          {
            id: 'vendedor',
            key: 'vendedor',
            label: 'Vendedor',
            sortable: true,
            tdClass: 'custom-width',
          },
        ],

        // Totalizadores
        totalizadores: {
          aprovado: 0,
          pendente: 0,
          negado: 0,
          contratoGerado: 0,
          contratoCancelado: 0,
          expirado: 0,

          apresentaAprovado: false,
          apresentaPendente: false,
          apresentaNegado: false,
          apresentaContratoGerado: false,
          apresentaContratoCancelado: false,
          apresentaExpirado: false,
        },
        apresentaAprovado: false,
        apresentaPendente: false,
        apresentaNegado: false,
        apresentaContratoGerado: false,
        apresentaContratoCancelado: false,
        apresentaExpirado: false,
        totalValorProposta: 0,
        totalValorParcela: 0,
        totalParcelas: 0,
        vendedores: [],
        vendedor: null,
      }
    },
    mounted() {
      this.carregarVendedores()
    },
    methods: {
      carregarVendedores() {
        useJwt
          .get(`cadastro/user/buscaVendedores/${this.userData.institutoSelecionado}`)
          .then((response) => {
            const vendedores = response.data
            this.vendedores = vendedores
          })
          .catch((error) => {
            console.error(error)
          })
      },
      carregarGrid() {
        this.isBusy = true
        this.totalRows = 0
        this.currentPage = 1

        let filtro = {
          institutoId: this.userData.institutoSelecionado,
          pesquisarPor: this.pesquisarPor.id ?? null,
          descricao: this.descricao ?? null,
          statusProposta: this.statusProposta.id ?? null,
          dataInicial: this.dataInicial ?? null,
          dataFinal: this.dataFinal ?? null,
          usuarioId: this.vendedor?.id ?? null,
        }

        useJwt
          .post('proposta/GetRelatorio', filtro)
          .then((response) => {
            if (!response.data.length > 0) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Alerta!',
                    text: 'Não há dados para exportar!',
                    icon: 'BellIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-right',
                  timeout: 8000,
                },
              )
              this.itemsTabela = []
              return
            }

            this.itemsTabela = response.data.map((item) => ({
              ...item,
              valorProposta: item.valor,
              valorParcela: item.valorPrimeiraParcela,
              taxa: formatarValorPorcentagem(item.taxa),
              dataGerado: formatarData(item.dataAprovado),
              dataCadastro: formatarData(item.createdAt),
              statusProposta: item.statusProposta,
              proposta: item.id,
              parcelas: item.parcelas,
              vendedor: item.usuario ? item.usuario.name : 'Sem vendedor',
            }))

            this.contabilizaPropostasPorStatus()
            this.totalRows = this.itemsTabela.length
            this.configuraTotalizadores()

            // Total de valor de propostas
            this.totalValorProposta = this.itemsTabela.reduce((acc, item) => acc + formatarParaNumber(item.valor), 0)
            // Total de valor de parcelas
            this.totalValorParcela = this.itemsTabela.reduce(
              (acc, item) => acc + formatarParaNumber(item.valorPrimeiraParcela),
              0,
            )
            // Total de número de parcelas
            this.totalParcelas = this.itemsTabela.reduce((acc, item) => acc + formatarParaNumber(item.parcelas), 0)

            this.items = response.data
            this.linhasTabela = this.itemsTabela

            let linhasPDF = response.data.map((item) => ({
              ...item,
              valorProposta: formatarValorReais(item.valor),
              valorParcela: formatarValorReais(item.valorPrimeiraParcela),
              taxa: formatarValorPorcentagem(item.taxa),
              dataGerado: formatarData(item.dataAprovado),
              dataCadastro: formatarData(item.createdAt),
              statusProposta: item.statusProposta,
              proposta: item.id,
              parcelas: item.parcelas,
            }))
            linhasPDF.push({
              dataCadastro: 'TOTAL',
              proposta: '',
              valorProposta: formatarValorReais(this.totalValorProposta),
              valorParcela: formatarValorReais(this.totalValorParcela),
              taxa: '',
              parcelas: this.totalParcelas,
              statusProposta: '',
              motivoNegado: '',
            })
            let linhasExcel = response.data.map((item) => ({
              ...item,
              valorProposta: Number(item.valor),
              valorParcela: Number(item.valorPrimeiraParcela),
              taxa: formatarValorPorcentagem(item.taxa),
              dataGerado: formatarData(item.dataAprovado),
              dataCadastro: formatarData(item.createdAt),
              statusProposta: item.statusProposta,
              proposta: item.id,
              parcelas: item.parcelas,
            }))
            linhasPDF.push({
              dataCadastro: 'TOTAL',
              proposta: '',
              valorProposta: this.totalValorProposta,
              valorParcela: this.totalValorParcela,
              taxa: '',
              parcelas: this.totalParcelas,
              statusProposta: '',
              motivoNegado: '',
            })
            this.linhasExcel = linhasExcel
            ;(this.colunasTabelas = this.currentFields.map((item) => ({
              label: item.label,
              key: item.key,
            }))),
              // Monta variável global de relatório PDF
              (this.parametroPDF = {
                dados: linhasPDF,
                colunas: this.colunasTabelas,
                institutoNome: this.userData.Instituto.nome,

                // Configura totalizadores
                totalizadores: {
                  ...this.totalizadores,
                  apresentaAprovado: this.apresentaAprovado,
                  apresentaPendente: this.apresentaPendente,
                  apresentaNegado: this.apresentaNegado,
                  apresentaContratoGerado: this.apresentaContratoGerado,
                  apresentaContratoCancelado: this.apresentaContratoCancelado,
                  apresentaExpirado: this.apresentaExpirado,
                  totalRows: this.totalRows,
                  totalValorProposta: this.totalValorProposta,
                  totalValorParcela: this.totalValorParcela,
                  totalParcelas: this.totalParcelas,
                },

                periodo: {
                  dataInicio: this.dataInicial,
                  dataFim: this.dataFinal,
                },
              })
          })
          .catch((error) => {
            console.error(error)
            this.isBusy = false
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      exportToPDF() {
        if (!this.itemsTabela.length > 0) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Alerta!',
                text: 'Não há dados para exportar!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }

        if (this.$refs.tabelaGrid) {
          let htmlContent = this.$refs.exportarPDF.$children[0].$refs.html2Pdfportrait.$el.innerHTML
          let nomeInstituto = this.userData.Instituto.nome
          let periodoFiltro = `Período: ${this.dataInicial} a ${this.dataFinal}`
          let nomeRelatorio = this.nomeRelatorio
          let informacoesInstituto = `
                    ${this.userData.Instituto.logradouro},
                    ${this.userData.Instituto.numero},
                    ${this.userData.Instituto.bairro},
                    ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf}
                    E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}
                  `
          generateAndDownloadPdf(
            this,
            htmlContent,
            nomeInstituto,
            periodoFiltro,
            nomeRelatorio,
            informacoesInstituto,
            null,
            this.userData.Instituto.id,
          )
        } else {
          console.error('Elemento da tabela não encontrado')
        }
      },
      exportToExcel() {
        if (!this.itemsTabela.length) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Alerta!',
                text: 'Não há dados para exportar!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }

        const itemsExcel = this.linhasExcel.map((item) => ({
          'Data de Cadastro': item.dataCadastro,
          Código: item.proposta,
          Nome: item?.segurado?.tomador?.nomeCompleto || '',
          'Valor Proposta': item.valorProposta,
          'Valor Parcela': item.valorParcela,
          Taxa: item.taxa,
          'Nº Parcelas': item.parcelas,
          Status: item.statusProposta,
          Observação: item.motivoNegado,
        }))

        itemsExcel.push({
          'Data de Cadastro': '',
          Código: '',
          'Valor Proposta': '',
          'Valor Parcela': '',
          Taxa: '',
          'Nº Parcelas': '',
          Status: '',
          Observação: '',
        })

        itemsExcel.push({
          'Data de Cadastro': 'Total de Propostas',
          Código: this.totalRows,
          'Valor Proposta': '',
          'Valor Parcela': '',
          Taxa: '',
          'Nº Parcelas': '',
          Status: '',
          Observação: '',
        })

        if (this.apresentaAprovado) {
          itemsExcel.push({
            'Data de Cadastro': 'Propostas Aprovadas',
            Código: this.totalizadores.aprovado,
            'Valor Proposta': '',
            'Valor Parcela': '',
            Taxa: '',
            'Nº Parcelas': '',
            Status: '',
            Observação: '',
          })
        }

        if (this.apresentaPendente) {
          itemsExcel.push({
            'Data de Cadastro': 'Propostas Pendentes',
            Código: this.totalizadores.pendente,
            'Valor Proposta': '',
            'Valor Parcela': '',
            Taxa: '',
            'Nº Parcelas': '',
            Status: '',
            Observação: '',
          })
        }

        if (this.apresentaNegado) {
          itemsExcel.push({
            'Data de Cadastro': 'Propostas Negadas',
            Código: this.totalizadores.negado,
            'Valor Proposta': '',
            'Valor Parcela': '',
            Taxa: '',
            'Nº Parcelas': '',
            Status: '',
            Observação: '',
          })
        }

        if (this.apresentaContratoGerado) {
          itemsExcel.push({
            'Data de Cadastro': 'Propostas com Contrato Consolidado',
            Código: this.totalizadores.contratoGerado,
            'Valor Proposta': '',
            'Valor Parcela': '',
            Taxa: '',
            'Nº Parcelas': '',
            Status: '',
            Observação: '',
          })
        }

        if (this.apresentaContratoCancelado) {
          itemsExcel.push({
            'Data de Cadastro': 'Propostas Canceladas',
            Código: this.totalizadores.contratoCancelado,
            'Valor Proposta': '',
            'Valor Parcela': '',
            Taxa: '',
            'Nº Parcelas': '',
            Status: '',
            Observação: '',
          })
        }

        if (this.apresentaExpirado) {
          itemsExcel.push({
            'Data de Cadastro': 'Propostas Expiradas',
            Código: this.totalizadores.expirado,
            'Valor Proposta': '',
            'Valor Parcela': '',
            Taxa: '',
            'Nº Parcelas': '',
            Status: '',
            Observação: '',
          })
        }

        // Usando os dados e colunas para criar a planilha
        const ws = XLSX.utils.json_to_sheet(itemsExcel)

        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Relatorio')

        // Salvando o arquivo
        XLSX.writeFile(wb, 'Relatorio.xlsx')
      },
      contabilizaPropostasPorStatus() {
        this.totalizadores = this.itemsTabela.reduce(
          (acc, item) => {
            if (item.statusProposta === 'Aprovado') {
              acc.aprovado += 1
            } else if (item.statusProposta === 'Pendente') {
              acc.pendente += 1
            } else if (item.statusProposta === 'Negado') {
              acc.negado += 1
            } else if (item.statusProposta === 'ContratoGerado') {
              acc.contratoGerado += 1
            } else if (item.statusProposta === 'ContratoCancelado') {
              acc.contratoCancelado += 1
            } else if (item.statusProposta === 'Expirado') {
              acc.expirado += 1
            }
            return acc
          },
          { aprovado: 0, pendente: 0, negado: 0, contratoGerado: 0, contratoCancelado: 0, expirado: 0 },
        )
      },
      configuraTotalizadores() {
        this.apresentaAprovado = false
        this.apresentaPendente = false
        this.apresentaNegado = false
        this.apresentaContratoGerado = false
        this.apresentaContratoCancelado = false
        this.apresentaExpirado = false

        if (
          this.statusProposta.id == 'Aprovado' ||
          this.statusProposta.id == 'Pendente/Aprovado' ||
          this.statusProposta.id == 'Todos'
        ) {
          this.apresentaAprovado = true
        }

        if (
          this.statusProposta.id == 'Pendente/Aprovado' ||
          this.statusProposta.id == 'Pendente' ||
          this.statusProposta.id == 'Todos'
        ) {
          this.apresentaPendente = true
        }

        if (this.statusProposta.id == 'ContratoGerado' || this.statusProposta.id == 'Todos') {
          this.apresentaContratoGerado = true
        }

        if (this.statusProposta.id == 'Negado' || this.statusProposta.id == 'Todos') {
          this.apresentaNegado = true
        }

        if (this.statusProposta.id == 'Expirado' || this.statusProposta.id == 'Todos') {
          this.apresentaExpirado = true
        }

        if (this.statusProposta.id == 'ContratoCancelado' || this.statusProposta.id == 'Todos') {
          this.apresentaContratoCancelado = true
        }

        if (this.statusProposta.id == 'Todos') {
          this.apresentaAprovado = true
          this.apresentaPendente = true
          this.apresentaNegado = true
          this.apresentaContratoGerado = true
          this.apresentaContratoCancelado = true
          this.apresentaExpirado = true
        }
      },
    },
    watch: {
      dataInicialDatePicker(newVal) {
        this.dataInicial = formatarData(this.dataInicialDatePicker)
      },
      dataFinalDatePicker(newVal) {
        this.dataFinal = formatarData(this.dataFinalDatePicker)
      },
    },
  }
</script>
<style>
  .custom-width {
    width: 300px;
  }

  #containerTotalizadores {
    display: flex !important;
    max-height: 4.5em !important;
    flex-wrap: wrap !important;
    flex-direction: column !important;
    align-content: flex-start !important;
  }
  .auto-width-headers .table thead th {
    width: auto !important;
    white-space: nowrap;
  }
  .auto-width-headers .table td {
    width: auto !important;
    white-space: nowrap;
  }
</style>
