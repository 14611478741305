<template>
  <b-card>
    <h2>Relatório de Simulador Externo</h2>
    <validation-observer ref="formPesquisar">
      <b-row>
        <b-col md="2">
          <b-form-group label="Data Inicial" label-for="dataInicial">
            <validation-provider #default="{ errors }" name="Data Inicial" rules="required">
              <b-input-group>
                <cleave
                  id="dataInicial"
                  type="text"
                  v-model="pesquisar.dataInicial"
                  placeholder="DD/MM/AAAA"
                  class="form-control cleave-height"
                  :options="cleaveDate"
                  :raw="false"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    size="sm"
                    button-variant="primary"
                    class="datePickerWithInput"
                    button-only
                    v-model="dataInicialDatePicker"
                    :hide-header="true"
                    :initial-date="new Date()"
                    :state="errors[0] ? false : null"
                    label-help=""
                    label-calendar="Calendário"
                    label-close-button="Fechar"
                    label-current-month="Mês atual"
                    label-next-decade="Próxima década"
                    label-next-month="Próximo mês"
                    label-next-year="Próximo ano"
                    label-prev-decade="Década anterior"
                    label-prev-month="Mês anterior"
                    label-prev-year="Ano anterior"
                    label-select-month="Selecione o mês"
                    label-select-year="Selecione o ano"
                    label-selected="Selecionado"
                    label-no-date-selected="Nenhuma data selecionada"
                    label-reset-button="Resetar"
                    label-today-button="Hoje"
                    label-today="Hoje"
                    locale="pt-br"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                    aria-controls="dataInicial"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group label="Data Final" label-for="dataFinal">
            <validation-provider
              #default="{ errors }"
              name="Data Final"
              :rules="{
                dataPassada: {
                  value: pesquisar.dataFinal,
                  dataParametro: pesquisar.dataInicial,
                  stringParametro: 'data inicial',
                },
                required: true,
              }"
            >
              <b-input-group>
                <cleave
                  id="dataFinal"
                  type="text"
                  v-model="pesquisar.dataFinal"
                  placeholder="DD/MM/AAAA"
                  class="form-control cleave-height"
                  :options="cleaveDate"
                  :raw="false"
                  v-on:keydown.enter="carregarGrid()"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    size="sm"
                    button-variant="primary"
                    class="datePickerWithInput"
                    button-only
                    v-model="dataFinalDatePicker"
                    :hide-header="true"
                    :initial-date="new Date()"
                    :state="errors[0] ? false : null"
                    label-help=""
                    label-calendar="Calendário"
                    label-close-button="Fechar"
                    label-current-month="Mês atual"
                    label-next-decade="Próxima década"
                    label-next-month="Próximo mês"
                    label-next-year="Próximo ano"
                    label-prev-decade="Década anterior"
                    label-prev-month="Mês anterior"
                    label-prev-year="Ano anterior"
                    label-select-month="Selecione o mês"
                    label-select-year="Selecione o ano"
                    label-selected="Selecionado"
                    label-no-date-selected="Nenhuma data selecionada"
                    label-reset-button="Resetar"
                    label-today-button="Hoje"
                    label-today="Hoje"
                    locale="pt-br"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                    aria-controls="dataFinal"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="auto">
          <b-button
            :disabled="isBusy"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 21px; width: 160px"
            @click="carregarGrid()"
          >
            {{ !isBusy ? 'Pesquisar' : '' }}
            <b-spinner small v-if="isBusy" />
          </b-button>
        </b-col>
        <b-col md="auto">
          <template>
            <b-dropdown variant="link" no-caret toggle-class="p-0" right :popper-opts="{ positionFixed: true }">
              <template #button-content>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" style="margin-top: 21px; width: 160px">
                  Exportar
                </b-button>
              </template>

              <b-dropdown-item href="#" @click="exportToPDF()">
                <feather-icon icon="FileTextIcon" size="16" />
                <span class="align-middle ml-50">PDF</span>
              </b-dropdown-item>

              <b-dropdown-item href="#" @click="exportToExcel()">
                <feather-icon icon="CheckCircleIcon" size="16" />
                <span class="align-middle ml-50" title="Exportar tabela para excel">Excel</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-col>
      </b-row>
    </validation-observer>

    <div class="mb-1">
      <span v-show="items.length > 0"
        >Total encontrado:
        <b-badge pill>
          {{ totalRows }}
        </b-badge>
      </span>
    </div>

    <b-table
      v-show="items.length > 0"
      striped
      responsive
      class="position-relative"
      id="tableRelatorioSimuladorExterno"
      :per-page="pesquisar.perPage"
      :items="items"
      :fields="fields"
      :busy="isBusy"
      :current-page="pesquisar.currentPage"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>

      <template #cell(createdAt)="row">
        {{ formatarData(row.item.createdAt) }}
      </template>

      <template #cell(statusProposta)="rowPropostas">
        <b-badge pill :variant="status[0][rowPropostas.item.statusProposta]">
          {{ rowPropostas.item.statusProposta }}
        </b-badge>
      </template>
    </b-table>

    <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        style="align-items: center"
        label="Por Página"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="pesquisar.currentPage"
        :total-rows="totalRows"
        :per-page="pesquisar.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <!-- Relatório PDF -->
    <div>
      <RelatorioSimuladorExternoPaisagem ref="exportarPDF" :parametro="parametroPDF" />
    </div>
  </b-card>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import moment from 'moment'
  import Cleave from 'vue-cleave-component'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import Ripple from 'vue-ripple-directive'
  import { formatarData, formatarDataGlobal, formatarValor } from '@/libs/utils'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import VueHtml2pdf from 'vue-html2pdf'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import RelatorioSimuladorExternoPaisagem from './components/relatorioSimuladorExternoPaisagem.vue'
  import { asDefault } from '@/views/pages/relatorio/exportTable'
  import { parse, isValid, format } from 'date-fns'
  import { generateAndDownloadPdf } from '@/utils/geradorPDF/geradorPDF.js'

  export default {
    components: {
      Cleave,
      ToastificationContent,
      ValidationProvider,
      ValidationObserver,
      VueHtml2pdf,
      BCardCode,
      formatarData,
      RelatorioSimuladorExternoPaisagem,
      asDefault,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        required,
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
          dataInicial: '',
          dataFinal: '',
        },
        dataInicialDatePicker: '',
        dataFinalDatePicker: '',
        cleaveDate: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
        formatarData,
        isBusy: false,
        acesso: {},
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        fields: [
          {
            key: 'createdAt',
            label: 'Criado em',
            formatter: (value, key, item) => {
              return formatarData(value)
            },
            sortable: true,
          },
          { key: 'cpf', label: 'CPF' },
          {
            key: 'nomeCompleto',
            label: 'Nome',
            formatter: (value, key, item) => (value ? value : 'Não informado'),
            sortable: true,
          },
          { key: 'email', label: 'E-mail', formatter: (value, key, item) => (value ? value : 'Não informado') },
          { key: 'telefone', label: 'Telefone', formatter: (value, key, item) => (value ? value : 'Não informado') },
          {
            key: 'valorContrato',
            label: 'Valor Empréstimo',
            formatter: (value, key, item) => formatarValor(value),
            sortable: true,
          },
          { key: 'quantidadeParcelas', label: 'Parcelas', sortable: true },
        ],

        // Relatório PDF
        parametroPDF: {
          dados: [],
          colunas: [],
          periodo: { dataInicio: '', dataFim: '' },
        },
        fieldsPDF: [
          {
            key: 'createdAt',
            label: 'Criado em',
            formatter: (value, key, item) => {
              return formatarData(value)
            },
          },
          { key: 'cpf', label: 'CPF' },
          { key: 'nomeCompleto', label: 'Nome', formatter: (value, key, item) => (value ? value : 'Não informado') },
          { key: 'email', label: 'E-mail', formatter: (value, key, item) => (value ? value : 'Não informado') },
          { key: 'telefone', label: 'Telefone', formatter: (value, key, item) => (value ? value : 'Não informado') },
          { key: 'valorContrato', label: 'Valor Empréstimo', formatter: (value, key, item) => formatarValor(value) },
          { key: 'quantidadeParcelas', label: 'Parcelas' },
        ],
        linhasTabela: 0,

        items: [],
        status: [
          {
            Aprovado: 'success',
            Pendente: 'warning',
            Negado: 'danger',
          },
        ],
        nomeRelatorio: 'Relatório de Simulador Externo',
      }
    },
    mounted() {
      let dataAtual = new Date()
      this.pesquisar.dataInicial = formatarData(new Date(dataAtual.getFullYear(), dataAtual.getMonth() - 1, 1))
      this.pesquisar.dataFinal = formatarData(new Date())
    },
    methods: {
      isDateValid() {
        const dataInicialFormatada = parse(this.pesquisar.dataInicial, 'dd/MM/yyyy', new Date())
        const dataFinalFormatada = parse(this.pesquisar.dataFinal, 'dd/MM/yyyy', new Date())

        if (!isValid(dataInicialFormatada) || !isValid(dataFinalFormatada)) {
          this.msgError('Erro: A data inserida não é válida.')
          return false
        }

        if (dataInicialFormatada > dataFinalFormatada) {
          this.msgError('Erro: Pesquisa inválida, a data inicial é maior que a data final.')
          return false
        }

        return true
      },
      carregarGrid() {
        this.$refs.formPesquisar.validate().then((success) => {
          if (success) {
            if (this.isDateValid(this.pesquisar.dataInicial) && this.isDateValid(this.pesquisar.dataFinal)) {
              this.isBusy = true
              useJwt
                .pesquisar('calculadoraEmprestimo/Getrelatorio', {
                  dataInicio: this.pesquisar.dataInicial ? formatarDataGlobal(this.pesquisar.dataInicial) : null,
                  dataFim: this.pesquisar.dataFinal ? formatarDataGlobal(this.pesquisar.dataFinal) : null,
                  institutoId: this.userData.institutoSelecionado,
                })
                .then((response) => {
                  const QUANTIDADE_LINHAS_PAISAGEM = 37

                  this.items = response.data
                  this.totalRows = this.items.length
                  this.linhasTabela = this.items

                  this.dividirLista(this.linhasTabela, QUANTIDADE_LINHAS_PAISAGEM)
                  // Monta variável global de relatório PDF
                  this.parametroPDF = {
                    dados: response.data,
                    colunas: this.fieldsPDF,
                    periodo: {
                      dataInicio: this.pesquisar.dataInicial,
                      dataFim: this.pesquisar.dataFinal,
                    },
                  }

                  if (!response.data?.length) this.$message.error('Não há dados para exportar!')
                })
                .catch((error) => {
                  console.error(error)
                  this.$message.error('Houve um erro ao realizar a consulta!')
                })
                .finally(() => {
                  this.isBusy = false
                })
            }
          }
        })
      },
      exportToPDF() {
        if (this.items.length > 0) {
          let htmlContent = this.$refs.exportarPDF.$children[0].$refs.html2Pdfportrait.$el.innerHTML
          let nomeInstituto = this.userData.Instituto.nome
          let periodoFiltro = `Período: ${this.dataInicial} a ${this.dataFinal}`
          let nomeRelatorio = this.nomeRelatorio
          let informacoesInstituto = `
                    ${this.userData.Instituto.logradouro},
                    ${this.userData.Instituto.numero},
                    ${this.userData.Instituto.bairro},
                    ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf}
                    E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}
                  `
          generateAndDownloadPdf(
            this,
            htmlContent,
            nomeInstituto,
            periodoFiltro,
            nomeRelatorio,
            informacoesInstituto,
            null,
            this.userData.Instituto.id,
          )
        } else {
          this.$message.error('Não há dados para exportar')
        }
      },
      transformarJson(jsonData) {
        const resultado = []

        jsonData.forEach((item) => {
          resultado.push({
            'CRIADO EM': formatarData(item.createdAt),
            CPF: item.cpf,
            'Nome Completo': !item.nomeCompleto ? 'Não informado' : item.nomeCompleto,
            'E-mail': !item.email ? 'Não informado' : item.email,
            Telefone: !item.telefone ? 'Não informado' : item.telefone,
            'Valor Empréstimo': !item.valorContrato ? 'Não informado' : Number(item.valorContrato),
            Parcelas: !item.quantidadeParcelas ? 'Não informado' : item.quantidadeParcelas,
          })
        })

        return resultado
      },
      exportToExcel() {
        if (this.items.length === 0) {
          this.$message.error('Não há dados para exportar')
          return
        }
        const listaExportacao = this.transformarJson(this.items)
        asDefault({ fileName: `RelatorioSimuladorExterno` }).export(listaExportacao)
      },
      dividirLista(listaOriginal, tamanhoMaximo) {
        let primeiraPagina = listaOriginal.slice(0, tamanhoMaximo)
        const restante = listaOriginal.slice(tamanhoMaximo)
        const sublistas = [primeiraPagina]
        let sublistaAtual = []

        restante.forEach((item, indice) => {
          if (sublistaAtual.length < tamanhoMaximo) {
            sublistaAtual.push(item)
          } else {
            sublistas.push(sublistaAtual)
            sublistaAtual = [item]
          }

          if (indice === restante.length - 1) {
            sublistas.push(sublistaAtual)
          }
        })

        this.linhasTabela = sublistas
      },
      msgError(Title) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: Title,
              icon: 'BellIcon',
              variant: 'danger',
            },
          },
          {
            position: 'top-right',
            timeout: 8000,
          },
        )
      },
    },
    watch: {
      dataInicialDatePicker() {
        this.pesquisar.dataInicial = formatarData(this.dataInicialDatePicker)
      },
      dataFinalDatePicker() {
        this.pesquisar.dataFinal = formatarData(this.dataFinalDatePicker)
      },
    },
  }
</script>

<style></style>
