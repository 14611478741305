<template>
  <div>
    <RelatorioRetrato>
      <span v-if="parametro.periodo.dataInicio != ''">Período: De {{ formatarData(parametro.periodo.dataInicio) }} </span>
      <span v-else-if="parametro.periodo.dataFim != ''"> Desde o início do sistema </span>
      <span v-if="parametro.periodo.dataFim != ''"> até {{ formatarData(parametro.periodo.dataFim) }} </span>
      <span v-else-if="parametro.periodo.dataInicio != ''"> até hoje </span>

      <b-row>
        <b-table
          striped
          small
          class="ExportarPDFParcela mt-3 custom-header-style"
          style="text-align: center"
          id="ExportarPDFParcelaId"
          :head-variant="'light'"
          :items="parametro.dados"
          :fields="parametro.colunas"
        >
        </b-table>
      </b-row>

      <div v-if="parametro.totalizadores">
        <div class="m-0 p-0 mt-1 ml-2">
          <p class="p-0 m-0" style="font-size: 0.8em">
            Total de Propostas: <b-badge variant="primary"> {{ parametro.totalizadores.totalRows }}</b-badge>
          </p>

          <p class="p-0 m-0" style="font-size: 0.8em" v-if="parametro.totalizadores.apresentaAprovado">
            Propostas Aprovadas: <b-badge variant="success"> {{ parametro.totalizadores.aprovado }}</b-badge>
          </p>
        </div>

        <div
          class="m-0 p-0 ml-2"
          v-if="parametro.totalizadores.apresentaPendente || parametro.totalizadores.apresentaContratoGerado"
        >
          <p class="p-0 m-0" style="font-size: 0.8em" v-if="parametro.totalizadores.apresentaPendente">
            Propostas Pendentes: <b-badge variant="warning"> {{ parametro.totalizadores.pendente }}</b-badge>
          </p>

          <p class="p-0 m-0" style="font-size: 0.8em" v-if="parametro.totalizadores.apresentaContratoGerado">
            Propostas com Contrato Consolidado:
            <b-badge variant="success"> {{ parametro.totalizadores.contratoGerado }}</b-badge>
          </p>
        </div>

        <div
          class="m-0 p-0 ml-2"
          v-if="parametro.totalizadores.apresentaNegado || parametro.totalizadores.apresentaContratoCancelado"
        >
          <p class="p-0 m-0" style="font-size: 0.8em" v-if="parametro.totalizadores.apresentaNegado">
            Propostas Negadas: <b-badge variant="danger"> {{ parametro.totalizadores.negado }}</b-badge>
          </p>

          <p class="p-0 m-0" style="font-size: 0.8em" v-if="parametro.totalizadores.apresentaContratoCancelado">
            Propostas Cancelado: <b-badge variant="danger"> {{ parametro.totalizadores.contratoCancelado }}</b-badge>
          </p>
        </div>

        <div class="m-0 p-0 ml-2">
          <p class="p-0 m-0" style="font-size: 0.8em" v-if="parametro.totalizadores.apresentaExpirado">
            Propostas Expiradas: <b-badge variant="secondary"> {{ parametro.totalizadores.expirado }}</b-badge>
          </p>
        </div>
      </div>
    </RelatorioRetrato>
  </div>
</template>

<script>
  import pdfMake from 'pdfmake/build/pdfmake'
  import pdfFonts from 'pdfmake/build/vfs_fonts'
  import RelatorioRetrato from '@/views/pages/relatorio/relatorioRetrato.vue'
  import { formatarData, formatarParaNumber, formatarValor, formatarValorPorcentagem } from '@/libs/utils'

  export default {
    components: {
      RelatorioRetrato,
      pdfMake,
      pdfFonts,
      formatarValor,
      formatarData,
    },
    props: {
      parametro: {
        type: Object,
        required: true,
        default: () => ({
          totalizadores: {
            totalRows: 0,
            apresentaAprovado: false,
            aprovado: 0,
            apresentaPendente: false,
            pendente: 0,
            apresentaContratoGerado: false,
            contratoGerado: 0,
            apresentaNegado: false,
            negado: 0,
            apresentaContratoCancelado: false,
            contratoCancelado: 0,
            apresentaExpirado: false,
            expirado: 0,
          },
          dados: [],
          colunas: [],
          periodo: {
            dataInicio: '',
            dataFim: '',
          },
        }),
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        formatarValor,
        formatarData,
      }
    },
  }
</script>
